import Birds from "../Components/Birds";
import Copyright from "../Components/Copyright";
import FAQS from "../Components/FAQS";
import Header from "../Components/Header";

function Home() {
  return (
    <>
      <Header />
      <Birds />
      {/* <Collection /> */}
      {/* <Nesting/> */}
      {/* <Highrise /> */}
      {/* <Roadmap /> */}
      <FAQS />
      <Copyright />
    </>
  );
}

export default Home;
