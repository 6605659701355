import React from "react";

function Birds() {
  return (
    <div className="container-fluid py-4 birds">
      <div className="container">
        <div className="row">
          <div className="col-md-6 p-5">
            <img src="/images/grid.png" alt="" className="w-100" />
          </div>
          <div className="col-md-6 p-5 text-white d-flex align-items-center justify-content-center flex-column">
            <h1 className="text-green section__heading mb-4">MOON CLIMBER</h1>
            - High Quality Pixel Art <br />
            - Instant Reveal <br />
            - No Roadmap <br />
            - No Discord <br />
            - Community Driven <br />
            {/* - CC0 <br /> */}
            - 7.5% Royalties <br />
            - 500 Reserved 4 The Team <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Birds;
