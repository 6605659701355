import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQS() {
  return (
    <div className="container-fluid">
      <div className="container p-5 mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="row text-green">
              <h1 className="section__heading">FAQ</h1>
            </div>
            <div className="row mt-5">
              {/* a1 */}
              <Accordion className="faqs-overall">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="faqs-open-color" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faqs-heading">
                    Is there a Discord?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faqs-open-color">
                    Nope, we are a Twitter focussed project.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/*a2  */}
              <Accordion className="faqs-overall">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="faqs-open-color" />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className="faqs-heading">
                    What is the mint price?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faqs-open-color">
                    {" "}
                    free + gas. Max 2x wallet.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* a3 */}
              {/* <Accordion className='faqs-overall'>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography>What is CCO?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    CC0. This means there is no copyright. You can mod,
                    distribute and perform the work, even for commercial
                    purposes, all without asking permission.
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
              {/* a4 */}
              <Accordion className="faqs-overall">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="faqs-open-color" />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography className="faqs-heading">
                    Where can I mint MoonHedgehogs?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faqs-open-color">
                    By minting above, and then on the secondary market, via
                    OpenSea and LooksRare.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* a5 */}
              <Accordion className="faqs-overall">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="faqs-open-color" />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <Typography className="faqs-heading">
                    {" "}
                    What is the roadmap?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faqs-open-color">
                    We got bored of seeing BS roadmaps presented in a hope to
                    gain trust. Instead, we aim to under promise and
                    overdeliver. Follow our Twitter and enjoy the journey.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* a6 */}
              <Accordion className="faqs-overall">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="faqs-open-color" />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <Typography className="faqs-heading">
                    Who is on the team?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faqs-open-color">
                    3 frens, 1 dev and 2 digital artists, NFTs lovers.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* a7 */}
              {/* <Accordion className='faqs-overall'>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel7a-content"
                  id="panel7a-header"
                >
                  <Typography>
                    How are the 10,000 MoonHedgehogs distributed?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
